<template>
    <div :class="{'container': isWorldCup}">
        <!-- desktop ranking table  v-if="!isMobile"    -->
        <div class="match-table__wrapper">
            <div v-show="rankingTable.isLoading" class="skeleton-table__wrapper">
            <div class="display-flex-sb mb-1rem" v-for="(o, index) in 10" :key="index">
                <div class="skeleton-child skeleton-table__left"></div>
                <div class="skeleton-child skeleton-table__right"></div>
            </div>
            </div>
            <!-- <Loading v-show="rankingTable.isLoading"></Loading> -->
            <MatchTable
            :isShowTabs="false"
            :type="'total'"
            :matchTableListData="matchTableList"
            :leagueInfo="league"
            v-show="!rankingTable.isLoading && rankingTable.isShow"
            ></MatchTable>
        </div>
        <!-- end desktop ranking table -->
    </div>
</template>

<script>
import config from "@/js/config.js";
import { mapGetters, mapActions } from "vuex";
import MatchTable from "@/components/match/Table.vue";

export default {
  name: "Standings",
  props: {
    leagueId: {
      type: Number,
      default: null,
      required: false,
    },
  },
  components: {
    MatchTable,
  },
  watch: {
    currentLocale: {
      handler(newVal, oldVal) {
        this.init();
      }
    },
  },
  beforeMount() {
    this.checkIfWorldCup();
  },
  data() {
    return {
        matchTableList: [],
        rankingTable: {
            isShow: false,
            isShowArrow: true,
            isLoading: false,
            currentSelectedLeagueIndex: 0,
            list: [],
            currentSelectedLeague: "",
        },
        league: {
          logo: "",
          country: "",
          leagueKey: "english-premier-league-36",
        },
    };
  },
  computed: {
    ...mapGetters([
      "isMobile",
      "currentLocale",
      "currentSportType",
      "userInfo",
      "isLogin",
      "isWorldCup",
    ]),
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      "getSoccerLeagueRanking",
      "checkIfIsWorldCup",
      "getFixtureByLeague",
    ]),
    init() {
        this.getSoccerLeagueRankingData();
    },
    checkIfWorldCup() {
      let result =
        this.$route.params.pathMatch === config.iframePaths.worldCup
          ? true
          : false;
      this.checkIfIsWorldCup(result);
    },
    async getSoccerLeagueRankingData(param) {
      this.rankingTable.isShow= true;
      this.rankingTable.isLoading = true;
      let params = {
        leagueIdList: this.$route.params.leagueId ?? this.leagueId, //''
      };
      let params1 = {
				leagueKey:this.league.leagueKey,
				isCallInterval:false,
				timeZone:this.$tools.getCurrentTimeZone()
			}
      const result = await this.getSoccerLeagueRanking(params);
      const result1 = await this.getFixtureByLeague(params1);
      this.getLeagueListForRankingTable(result.result);
      this.rankingTable.isLoading = false;
      if (result1.message === "Success") {
        this.league.logo = result1.result.logo;
        this.league.country = result1.result.country;
      }
    },

    //handle ranking table
    getLeagueListForRankingTable(result) {
      if (result.length === 0) {
        this.rankingTable.isShow = false;
      } else {
        this.rankingTable.isShow = true;
        this.rankingTable.list = result;
        this.matchTableList = result;
        // this.handleLeagueListRankingTable();
      }
    },
  },
};
</script>

<style></style>
