<template>
  <div class="live-stream-hint" v-show="listingHasLiveStream">{{ $t("WATCH_LIVE_HINT_PART_1") }}<span class="live-icon"></span>{{ $t("WATCH_LIVE_HINT_PART_2") }}</div>
  <div class="live-stream-hint" v-show="!listingHasLiveStream">{{ $t("NO_LIVE_TODAY_HINT") }}</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["listingHasLiveStream"]),
  },

}
</script>

<style scoped>
.live-stream-hint {
  font-size: 0.688rem;
  font-weight: 400;
  color: #FFFFFF;
  margin: 0.5rem 0 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.live-stream-hint .live-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.5rem;
  background: url("../../../static/images/icons/icon_live_video.gif") center/cover no-repeat;
}
</style>
