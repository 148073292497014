<template>
  <div class="live-stream-on-listing-compact">
    <!-- poster="../../../static/images/world_cup_live_countdown_bg.jpg"  -->
    <video 
      :id="videoEleId"
      class="video video-js vjs-default-skin w-100 match-detail__video-live" 
      poster="../../../static/images/epl_live_countdown_bg.jpg" 
      autoplay
      muted
      controls 
      playsinline
      @mouseover.stop="toggleChannelList"
      @touchstart.stop="toggleChannelList"
      @mouseout.stop="toggleChannelList(false)"
    ></video>
    <div 
      :id="channelsWrapper" 
      class="live-stream-channels-container"
      @mouseover="toggleChannelList"

      @click="toggleChannelGroup"
      @touchstart="toggleChannelGroup"
      v-show="showCurrentChannel"
    >
      <div class="live-stream-current-channel-pill" v-if="match.liveStreamList">
        <div>{{ $t("CHANNEL") }} {{ selectedChannel }}</div>
        <svg v-if="!isMobile" width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.48375 0.516251L5.5 4.52375L9.51625 0.516251L10.75 1.75L5.5 7L0.25 1.75L1.48375 0.516251Z" fill="#CFD9DE"/>
        </svg>
        <svg v-if="isMobile" width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.64168 0.498901L4.12793 2.97973L6.61418 0.498901L7.37793 1.26265L4.12793 4.51265L0.87793 1.26265L1.64168 0.498901Z" fill="#CFD9DE"/>
        </svg>
      </div>
    </div>
    <div
      :id="channelsGroup"
      class="live-stream-channels-group-container" 
      v-show="showChannelList">
      <div class="live-stream-channels-group" v-if="match.liveStreamList">
        <div class="live-stream-channel-pill" :class="{'selected': match.selectedVideoSource === i}" v-for="(channel, i) in match.liveStreamList" :key="i" @click="initLiveVideo(false, true, i)" @touchstart="initLiveVideo(false, true, i)">{{ $t("CHANNEL") }} {{ i + 1 }}</div>
      </div>
    </div>
    
    <!-- <div 
      :id="channelsWrapper" 
      class="live-stream-channels-container"
    >
      <div class="live-stream-channel-pill" :class="{'selected': match.selectedVideoSource === i}" v-for="(channel, i) in liveMatchDetailsObj.liveStreamList" :key="i" @click="initLiveVideo(false, true, i)">{{ $t("CH.") }} {{ i + 1 }}</div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Hls from "hls.js";
export default {
  props: {
    liveMatchDetailsObj: {
      type: Object,
      required: true,
    },
    selectedChannelList: {
      type: Array,
      required: true,
    },
  },
  watch: {
    liveMatchDetailsObj: {
      handler(newVal, oldVal) {
        if (newVal)
          this.setVideoLink();
      }
    },
    defaultVideoToPlay: {
      handler(newVal, oldVal) {
        if (newVal)
          this.setFirstTimeLoadVideoLink();
      }
    },
  },
  computed: {
    ...mapGetters([
      "isMobile", 
      "defaultVideoToPlay",
      "matchListDataInterval",
    ]),
  },
  data() {
    return {
      match: {
        id: null,
        liveStreamList: [],
        selectedVideoSource: 0,
        selectedVideoUrl: null,
        hls: null,
        // previousId: null,
      },
      // matchList: [],
      videoEleId: "LiveStreamPlayerComponent",
      channelsWrapper: "ChannelsWrapper",
      channelsGroup: "ChannelsGroup",
      showVideo: false,
      showCurrentChannel: false,
      showChannelList: false,
      isToggleChannelList: false,
      selectedChannel: null,
    };
  },
  methods: {
    // if liveMatchDetailsObj - matchId same as previous one, then showVideo = false, else true
    // toggleVideoDisplay() {
    //   this.liveMatchDetailsObj;
    //   if (match.id === match.previousId) {
    //     this.showVideo = false;
    //   } else {
    //     this.showVideo = true;
    //   }
    // },
    setVideoLink() {
      // during video playing, if live stream list changed only replace video src
      if (this.matchListDataInterval) {
        let tempOldUrlList = this.match.liveStreamList.map(obj => this.getPureLiveStreamData(obj));
        let tempNewUrlList = this.liveMatchDetailsObj.liveStreamList.map(obj => this.getPureLiveStreamData(obj));
        let oldLiveStreamUrl = JSON.stringify(tempOldUrlList);
        let newLiveStreamUrl = JSON.stringify(tempNewUrlList);
        if (oldLiveStreamUrl !== newLiveStreamUrl) {
          this.match.id = this.liveMatchDetailsObj.matchId;
          this.match.liveStreamList = this.liveMatchDetailsObj.liveStreamList;

          this.initLiveVideo(true);
        }
      } else {
        this.match.id = this.liveMatchDetailsObj.matchId;
        this.match.liveStreamList = this.liveMatchDetailsObj.liveStreamList;

        this.initLiveVideo(true);
      }
    },
    setFirstTimeLoadVideoLink() {
      this.match.id = this.defaultVideoToPlay.matchId;
      this.match.liveStreamList = this.defaultVideoToPlay.liveStreamList;

      this.initLiveVideo(true);
    },
    getPureLiveStreamData(obj) {
      let url = obj.url.split("?")[0]; // omit the query string of the url
      return {
        fromBg: obj.fromBg,
        name: obj.name,
        state: obj.state,
        type: obj.type,
        url: url
      };
    },
    // offChannelLisyonly() {
    //   this.showCurrentChannel = false;
    //   console.log("OK")
    // },
    toggleChannelList(bool = true) {//matchId
      let theChannelWrapper = document.getElementById(
        this.channelsWrapper
      );
      let video = document.getElementById(this.videoEleId);
      let toggleTimeout;
      let actions = ["mousemove", "touchmove"];
      if (this.match.liveStreamList.length > 0) {
        actions.forEach((action) => {
          video.removeEventListener(action, () => {});
        });

        if (bool) {
          this.showCurrentChannel = true;
          // theChannelWrapper.style.opacity = 1;
        } else {
          // theChannelWrapper.style.opacity = 0;
          this.showCurrentChannel = false;
          this.toggleChannelGroup(false);
        }

        // // this.matchList.forEach((league) => {
        //   // league.matchList.forEach((match) => {
        //     // if (match.matchId === matchId) {
        //       theChannelWrapper.style.opacity = 1;
        //       toggleTimeout = setTimeout(() => {
        //         theChannelWrapper.style.opacity = 0;
        //         clearTimeout(toggleTimeout);
        //       }, 2000);

        //       actions.forEach((action) => {
        //         video.addEventListener(action, () => {
        //           clearTimeout(toggleTimeout);
        //           theChannelWrapper.style.opacity = 1;
        //           toggleTimeout = setTimeout(() => {
        //             theChannelWrapper.style.opacity = 0;
        //             clearTimeout(toggleTimeout);
        //           }, 2000);
        //         });
        //       });
        //     // }
        //   // });
        // // });
      }
    },
    toggleChannelGroup(bool = true) {
      let theChannelGroup = document.getElementById(this.channelsGroup);
      if (bool) {
        this.showChannelList = true;
        this.showCurrentChannel = true;
      } else {
        this.showChannelList = false;
        this.showCurrentChannel = false;
      }
    },

    switchToNewLiveStreamChannel(theMatch, fromSwitchChannel = false) {
      this.selectedChannelList.forEach((selectedChannel) => {
        if (theMatch.id === selectedChannel.matchId) {
          // theMatch.selectedVideoSource = selectedChannel.selectedVideoSource;
          this.$emit('selectedVideoSourceChanged', theMatch.id, theMatch.selectedVideoSource);
        }
      });
      if (fromSwitchChannel) {
        this.selectedChannel = theMatch.selectedVideoSource + 1;
      }
      this.toggleChannelGroup(false);
      let theChannelWrapper = document.getElementById(this.channelsWrapper);
      if (fromSwitchChannel) {
        // theChannelWrapper.style.opacity = 0;
        this.showCurrentChannel = false;
      }
    },

    initLiveVideo(initialize = false, isSwitchChannel = false, switchToChannel = 0) {//matchId
      // need to verify if containLiveStream, liveStreamList beforehand
      this.$nextTick(() => {
        // this.matchList.forEach((league) => {
          // league.matchList.forEach((match) => {
            // if (match.matchId === matchId) {
              let video = document.getElementById(this.videoEleId);
              // let videoUrl;
              if (initialize) {//video.src === ""
                if (this.match.hls)
                  this.match.hls.destroy();
                this.match.selectedVideoUrl = this.match.liveStreamList[0].url;
                this.selectedChannel = 1;
                this.match.selectedVideoSource = 0;
                this.switchToNewLiveStreamChannel(this.match);
              } else if (isSwitchChannel) {
                this.match.selectedVideoUrl = this.match.liveStreamList[switchToChannel].url;
                this.selectedChannelList.forEach((match) => {
                  if (match.matchId === this.match.id) {
                    this.match.selectedVideoSource = switchToChannel;
                  }
                });

                this.switchToNewLiveStreamChannel(this.match, isSwitchChannel);
              }
              if (video.canPlayType("application/vnd.apple.mpegurl")) {
                // if (video.src === "" || isSwitchChannel) {
                  video.src = this.match.selectedVideoUrl;
                  video.play();
                // }
                // if (isSwitchChannel) {
                //     video.src = videoUrl;
                //     video.play();
                // }
              } else if (Hls.isSupported()) {
                if (video.src === "" || isSwitchChannel) {
                  if (isSwitchChannel && this.match.hls) {
                    this.match.hls.destroy();
                    clearInterval(this.match.hls.bufferTimer);
                    this.match.hls = null;
                  }
                  let hls = new Hls();
                  hls.loadSource(this.match.selectedVideoUrl);
                  hls.attachMedia(video);
                  this.match.hls = hls;
                  if (isSwitchChannel) {
                    video.play();
                  }
                }
              }

              // let playPromise;
              // if (video.src === "") {
              //     playPromise = video.play();
              //     if (playPromise !== undefined) {
              //         playPromise.then(() => {
              //             video.pause();
              //         }).catch(err => {});
              //     }
              // }
            // }
          // });
        // });

        this.playLatestLivstream();
      });
    },

    // play latest frame of live stream after back from pause
    playLatestLivstream() {
      let videos = document.getElementsByTagName("video");
      videos.forEach((video) => {
        video.removeEventListener("play", () => {});
      });
      videos.forEach((video) => {
        video.addEventListener("play", () => {
          let videoPlaying = document.getElementById(video.id);
          if (videoPlaying.duration === Infinity) {
            let url;
            this.selectedChannelList.forEach((selectedChannel) => {
              if (selectedChannel.matchId == this.match.id) {//video.id
                url = selectedChannel.selectedVideoURL;
              }
            });
            videoPlaying.src = url;
            videoPlaying.play();
          } else if (!isNaN(videoPlaying.duration)) {
            videoPlaying.currentTime = videoPlaying.duration - 1;
          }
        });
      });
    },
  },
};
</script>

<style scoped>
video::-webkit-media-controls-timeline,
video::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-time-remaining-display {
  display: none;
}
.live-stream-on-listing-compact {
  background: #212b30;
  /* border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem; */
  border-radius: 0.5rem;
  padding: 0.625rem 0.5rem;
  position: relative;
  margin-bottom: 0.5rem;
}
.live-stream-on-listing-compact video {
  width: 100%;
  height: auto;
  z-index: 1;
}
.live-stream-channels-container {
  display: flex;
  padding-top: 0.625rem;
  position: absolute;
  left: 5%;
  bottom: 15%;
  z-index: 2;
  /* transition: opacity 0.2s ease-out; */
}
.live-stream-channels-group-container {
  position: absolute;
  left: 5%;
  bottom: 15%;/*-0.2%*/
  z-index: 2;
}
.live-stream-channels-group {
  display: flex;
  flex-direction: column;
  padding-top: 0.625rem;
  position: absolute;
  /* left: 5%;
  bottom: -0.2%;
  z-index: 2; */
  /* transition: opacity 0.2s ease-out; */
  background: #35454D;
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  min-width: 6.938rem;
  box-sizing: border-box;
  /* opacity: 0; */
}
.live-stream-current-channel-pill {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #CFD9DE;
  font-weight: 700;
  font-size: 0.875rem;
  background: #212B30;
  border-radius: 1.5rem;
  padding: 0.375rem 0.5rem 0.438rem 0.75rem;
  cursor: pointer;
}
.live-stream-current-channel-pill svg {
  margin-left: 0.5rem;
}
.live-stream-channel-pill {
  color: #ffffff;
  /* background: var(--bg-theme); */
  padding: 0.3rem 0.75rem;
  /* margin-right: 0.4rem;
  border-radius: 1rem; */
  cursor: pointer;
  font-size: 0.688rem;/*0.875rem;*/
  font-weight: 400;/*bold*/
  /* opacity: 0.5; */
}
.live-stream-channel-pill.selected {
  background: var(--bg-selected);
  color: #000000;
}
@media (max-width: 768px) {
  .live-stream-on-listing-compact video {
    height: 74vw;
  }
}
@media (max-width: 320px) {
  .live-stream-on-listing-compact video {
    height: 80vw;
  }
  .live-stream-channels-container {
    bottom: 24%;
  }
}
</style>
