<template>
  <div class="gameweek-pill" v-show="!isMobile">{{ $t("GAMEWEEK") }} <span v-show="gameWeekNumber > 0">{{ gameWeekNumber }}</span></div>
  <div class="gameweek-pill" v-show="isMobile">{{ $t("GW") }} <span v-show="gameWeekNumber > 0">{{ gameWeekNumber }}</span></div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    gameWeekNumber: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
}
</script>

<style scoped>
.gameweek-pill {
  border-radius: 1.5rem;
  border: 1px solid #FFFFFF;
  padding: 0.375rem 0.844rem;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 0.875rem;
}
@media (max-width: 768px) {
  .gameweek-pill {
    font-size: 0.688rem;
    padding: 0.563rem 0.469rem;
  }
}
</style>
